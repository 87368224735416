import React from "react"
import { graphql } from "gatsby"
import { H1, PX3, Max2xl, Max5xl } from "../components/blocks"
import moment from "moment"
import { isValidDate } from "../utils/datetime"

import Layout from "../components/layout"
import { AppointmentIndex } from "../components/appointment"
import SEO from "../components/seo"

export default ({ data, location, filter, title, description }) => {
  const appointments = data.appointments.edges
  // console.dir(appointments)
  return (
    <Layout location={location} title={title}>
      <SEO title={title} description={description} />
      <Max5xl className="h-full">
        <Max2xl className="h-full">
          <PX3>
            <H1>{title}</H1>
            <AppointmentIndex appointments={appointments} filter={filter} />
          </PX3>
        </Max2xl>
      </Max5xl>
    </Layout>
  )
}

export const filterCurrent = a =>
  isValidDate(a.frontmatter.end)
    ? moment().isSameOrBefore(a.frontmatter.end, "day")
    : isValidDate(a.frontmatter.start) &&
      moment().isSameOrBefore(a.frontmatter.start, "day")

export const query = graphql`
  fragment AllAppointments on Query {
    appointments: allMarkdownRemark(
      filter: { fileAbsolutePath: { glob: "**/content/appointments/**/*.md" } }
      sort: { fields: [frontmatter___start], order: ASC }
    ) {
      edges {
        node {
          ...AppointmentInformation
        }
      }
    }
  }
  fragment AllAppointmentsDesc on Query {
    appointments: allMarkdownRemark(
      filter: { fileAbsolutePath: { glob: "**/content/appointments/**/*.md" } }
      sort: { fields: [frontmatter___start], order: DESC }
    ) {
      edges {
        node {
          ...AppointmentInformation
        }
      }
    }
  }
  fragment CurrentAppointments on Query {
    appointments: allMarkdownRemark(
      filter: {
        fileAbsolutePath: { glob: "**/content/appointments/**/*.md" }
        frontmatter: { state: { eq: "current" } }
      }
      sort: { fields: [frontmatter___start], order: ASC }
    ) {
      edges {
        node {
          ...AppointmentInformation
        }
      }
    }
  }
`
