import React from "react"
import { graphql } from "gatsby"
import Termine, { filterCurrent } from "../templates/termine.jsx"

export default ({ data, location }) => (
  <Termine
    data={data}
    location={location}
    title="Termine"
    description="Die aktuellen Termine Rund um das Thema Klimaschutz in Marburg"
    filter={filterCurrent}
  />
)

export const query = graphql`
  query selectedappointments {
    ...CurrentAppointments
  }
`
